import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import "./Reviews.css";
import { format } from 'date-fns';
import { FaFacebook } from "react-icons/fa";
import { FaYelp } from "react-icons/fa";

const supabase = createClient("https://lbyqwuvefoyslzlodulq.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxieXF3dXZlZm95c2x6bG9kdWxxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ0Njg4OTAsImV4cCI6MjA0MDA0NDg5MH0.9Uf6MsRkSzDpPOXqIXkUbwcnI2lcLryfDUj9iknREqk")

function Reviews() {
    const [reviews, setReviews] = useState([]);

    async function getReviews() {
        const { data } = await supabase.from("reviews").select()
        setReviews(data);
    }

    useEffect(() =>{
        getReviews()
    },[])

    if(reviews) {
        return(
            <>
            <div id="Reviews">
                <div className="header">
                    <h1>OUR CLIENT TESTIMONIALS</h1>
                </div>
                { (reviews.length === 0)  && (
                    <div className="review-card">
                    Waiting for our first client review! Meanwhile, checkout:
                    <div className="links">
                        <FaFacebook className="facebook" size="30px"/>
                        <FaYelp className="yelp" size="30px"/>
                    </div>
                </div>
                )}
                { (reviews.length > 0) && (
                    <div className="reviews">
                    {reviews.map((review) => {
                        return (
                            <div className="review-card">
                                <div className="review"><em>"{review.review}"</em></div>
                                <div className="name">{review.first_name}</div>
                                <div className="date">{format(review.created_at, 'MMMM dd, yyyy')}</div>
                            </div>
                        )
                    })}
                </div>

                )}
            </div>
            <div className="push"></div>
            </>
        )
    }
}

export default Reviews
