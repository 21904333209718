import Slider from "react-slick";
import "./Carousel.css"
import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { format } from 'date-fns';

const supabase = createClient("https://lbyqwuvefoyslzlodulq.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxieXF3dXZlZm95c2x6bG9kdWxxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ0Njg4OTAsImV4cCI6MjA0MDA0NDg5MH0.9Uf6MsRkSzDpPOXqIXkUbwcnI2lcLryfDUj9iknREqk")

export default function SimpleSlider() {
    const [reviews, setReviews] = useState([]);

    async function getReviews() {
        const { data } = await supabase.from("reviews").select()
        setReviews(data);
    }

    useEffect(() =>{
        getReviews()
    },[])

    console.log("reviews:", reviews)

    let short_list = [];

    if (reviews.length >= 3) {
        short_list = reviews.slice(Math.max(reviews.length - 3, 0))
    }

    console.log("short list:", short_list)

    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000
    };
    return (
        <div id="Carousel">
            {/* {short_list.map((review) => {
                return (
                    <div className="reviews">
                        <h3 className="review"><em>"{review.review}"</em></h3>
                        <div className="name">{review.first_name}</div>
                        <div className="date">{format(review.created_at, 'MMMM dd, yyyy')}</div>
                        </div>
                )
            })} */}
        { short_list.length && (
            <Slider className="slider" {...settings}>
            {short_list.map((review) => {
                return (
                    <div className="reviews">
                        <h3 className="review"><em>"{review.review}"</em></h3>
                        <div className="name">{review.first_name}</div>
                        <div className="date">{format(review.created_at, 'MMMM dd, yyyy')}</div>
                    </div>
                )
            })}
        </Slider>

        )}
      </div>
    );
  }
