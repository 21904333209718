import './Landing.css';
import { NavLink } from "react-router-dom";
import garage1 from "./images/pexels-jan-van-der-wolf-11680885-18408820.jpg";
import ScrollAnimation from 'react-animate-on-scroll';


function Landing() {
    return (
        <>
        <div id="Landing">
            <div className='container'>
                <img src={garage1} alt="garage door cover" className="placeholder" />
                <NavLink to="/contact">
                    <div className="button">Schedule now</div>
                </NavLink>
            </div>
            </div>
                <div className="mission-statement">
                    <ScrollAnimation animateIn="fadeIn">
                        <h3>OUR MISSION</h3>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn">
                        <div className= "description">
                            At Apex Overhead Investment Inc, we specialize in the expert repair and seamless installation of garage doors. With a commitment to quality craftsmanship and customer satisfaction, we offer a comprehensive range of services to meet your residential or commercial needs. Whether it's fixing a malfunctioning door, replacing worn-out parts, or installing a brand-new garage door system, our skilled technicians ensure every job is completed with precision and reliability. Trust Apex Overhead Investment, for all your garage door solutions, providing peace of mind and enhancing the functionality and aesthetics of your property.
                        </div>
                    </ScrollAnimation>
                </div>

        </>
    )
}

export default Landing;
