import "./ContactForm.css";
// import { IoChevronForwardOutline } from "react-icons/io5";
// import { IoChevronBack } from "react-icons/io5";
import React, { useState, useEffect, useMemo } from 'react';
import emailjs from '@emailjs/browser';
import { IoIosArrowRoundForward } from "react-icons/io";


function ContactForm() {
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState({
        first: '',
        last: '',
        email: '',
        phone: '',
        about: '',
        other: '',
        date: '',
        time: '',
        garageServices: ''
    })

    let services = useMemo(() => [], []);

    useEffect(() => {
        setFormData({...formData, garageServices: services.toString()})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services.length, services])

    const handleAddService = (e) => {
        const value = e.target.value;
        if (!services.includes(value)) {
            services.push(value)
        } else {
            const index = services.indexOf(value);
            services.splice(index,1)
        }
        console.log("services:", services)
        console.log("garage: ", formData.garageServices)
    }

    const handleFormChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    };

    const handleSend = (e) => {
        e.preventDefault();
        console.log("form data:", formData)
        emailjs.send('service_codm7iy', "template_cn7sxvf", formData, {
            publicKey: 'FAcdoyWfUIfw9mYNA'
        }).then(
            (response) => {
                console.log("Email sent.", response.status, response.text)
                setFormData({
                    first: '',
                    last: '',
                    email: '',
                    phone: '',
                    about: '',
                    other: '',
                    date: '',
                    time: '',
                    garageServices: []
                });
                setSent(true)
            },
            (error) => {
                console.log("Email failed to send.", error)
                setError(true)
            }
        )
    }

    return(
        <>
        <div id="Contact">
            <div className="container">
                    <h1>Schedule a consultation with us
                        <IoIosArrowRoundForward
                        className="arrow"
                        size="150px"/></h1>
            </div>
            <div className="form">
                <form onSubmit={handleSend}>
                    <div className="row">
                        <div className="input">
                            <label for="first">First Name:</label>
                                <input
                                    placeholder="e.g. John"
                                    onChange={handleFormChange}
                                    value= {formData.first}
                                    name="first"
                                    type= "text"
                                    required/>
                        </div>
                        <div className="input">
                            <label for="last">Last Name:</label>
                            <input
                                placeholder="Last name"
                                onChange= {handleFormChange}
                                value={formData.last}
                                name='last'
                                type="text"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input">
                            <label for="email">Email:</label>
                                <input
                                    placeholder="Email"
                                    onChange = {handleFormChange}
                                    value = {formData.email}
                                    name='email'
                                    type="email"/>
                        </div>
                        <div className="input">
                            <label for="phone">Phone:</label>
                                <input
                                    placeholder="(123) 456-7890"
                                    onChange = {handleFormChange}
                                    type='text'
                                    name='phone'
                                    value = {formData.phone}
                                    required/>
                        </div>
                    </div>
                    <div className="services">
                        <label>Which services are you interested in?</label>
                            <div>
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    id="checkbox1"
                                    value="Garage Door Opener"
                                    onClick={handleAddService}
                                />
                            <label for="checkbox1">Garage Door Opener</label>
                                <input
                                    type="checkbox"
                                    id="checkbox2"
                                    className="checkbox"
                                    value="Springs and Off-Track"
                                    onClick={handleAddService}
                                />
                            <label for="checkbox2">Springs and Off-Track</label>
                                <input
                                    type="checkbox"
                                    id="checkbox3"
                                    className="checkbox"
                                    value="Garage Door"
                                    onClick={handleAddService}
                                />
                            <label for="checkbox3">Garage Door</label>
                                <input
                                    type="checkbox"
                                    id="checkbox4"
                                    className="checkbox"
                                    value="Repairs"
                                    onClick={handleAddService}
                                />
                            <label for="checkbox4">Repairs</label>
                        </div>
                    </div>
                    <div className="services">
                        <label for="date">Preferred date and time:</label>
                        <div className="datetime">
                            <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleFormChange}
                                required
                            />
                            <input
                                type="time"
                                name="time"
                                value={formData.time}
                                onChange={handleFormChange}
                            />
                        </div>
                    </div>
                    <div className="services">
                        <label for="about">Tell us about your garage:</label>
                        <textarea
                            type="text"
                            name="about"
                            value={formData.about}
                            onChange={handleFormChange}
                        />
                    </div>
                    <div className="services">
                        <label for="other">Anything else you would like us to know:</label>
                        <textarea
                            type="text"
                            name="other"
                            value={formData.other}
                            onChange={handleFormChange}
                        />
                    </div>
                    <div className="button-container">
                        <button type="submit"> Submit </button>
                    </div>
                    {sent && (
                        <div className="submit-message">
                            <em>Message sent! Look out for a message from us!</em>
                        </div>
                    )}
                    {error && (
                        <div className="submit-message">
                            <em>Message failed to send. Please try again.</em>
                        </div>
                    )}
                </form>
            </div>
        </div>
        </>
    )
}

export default ContactForm;
