import './AboutUs.css';
import truck from './images/IMG_1672.jpg';
import ScrollAnimation from 'react-animate-on-scroll';


function AboutUs() {
    return (
        <>
        <ScrollAnimation animateIn="fadeIn">
                <div id="AboutUs">
                    <img src={truck} alt="truck" className="placeholder" />
                    <div className="description">
                        <h3>ABOUT US</h3>
                        Apex Overhead Investments Inc was founded in 2023 by Jesus Martinez. Located in La Puente, our company began with a simple mission: to provide reliable, high-quality garage door solutions to homeowners and businesses in our community. Apex Overhead Investments Inc offers a comprehensive range of services, including list primary services such as garage door installation, repair, maintenance, or customization. Our team is equipped with the latest technology and training to ensure that we meet the highest standards of safety and efficiency. We pride quality work and great service for our customers and great communication. Which we have earned us a reputation for excellence in San Bernandino County and Los Angeles County.
                    </div>
                </div>
        </ScrollAnimation>
        </>
    )
}

export default AboutUs;
