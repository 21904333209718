import './Services.css'
import service1 from "./images/71537499372__2681E5C1-60EF-42FD-AB32-3EE7081FA186 - Jesus Martinez.jpeg";
import service2 from "./images/IMG_0649 - Jesus Martinez.jpeg";
import service3 from "./images/IMG_0966 - Jesus Martinez.jpeg";
import service4 from "./images/1AB49225-99D9-4B0D-8823-7095E098BC83_4_5005_c.jpeg";
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';



const services = [
    {
        title: "Garage Door Opener",
        img: service1,
        description: "A garage door opener is a device that automatically opens and closes the garage door at the touch of a button, mounted on the ceiling and operated by a motor driving a trolley along a rail. It offers convenience and security for homeowners, allowing them to access the garage easily without having to manually lift and lower the door."
    },
    {
        title: "Springs And Off-Track",
        img: service2,
        description: "Springs are an essential component of a garage door system that help to counterbalance the weight of the door, making it easier to open and close. There are two main types of garage door springs: torsion springs, which are mounted above the door and unwind to lift the door, and extension springs, which are mounted on the sides of the door and stretch to lift the door. \n \n Off-track doors occur when the garage door rollers come out of the tracks, causing the door to become misaligned and unable to open or close properly. This can happen due to worn out rollers, loose tracks, or other issues with the door mechanism. Off-track doors can be a safety hazard and should be repaired by a professional technician to ensure the door operates safely and smoothly."
    },
    {
        title: "Garage Door",
        img: service3,
        description: "A garage Door can be operated manually or with a garage door opener. Garage doors come in a variety of styles, sizes, and designs to suit different architectural styles and personal preferences. They play a key role in providing security and protection for the items stored in the garage. Regular maintenance and proper installation are important to ensure the garage door functions correctly and safely."
    },
    {
        title: "Repairs",
        img: service4,
        description: "We fix any type of Garage Doors and Garage Door Openers. With quality parts and brand new parts."
    }
]

function ServiceContainer(props) {
    const [expand, setExpand] = useState(false)
    const image = props.image
    const title = props.title
    const description = props.description
    console.log("props:", props)
    console.log("image", image)
    return (
        <>
        {!expand &&
            <div className="service-container" onClick={()=>setExpand(!expand)}>
                <img src={image} alt={title}></img>
                <div>{title.toUpperCase()}</div>
                <IoIosArrowDropdown
                    className="dropdown-btn"
                    size="40px"
                    onClick={()=>setExpand(!expand)}
                />
            </div>
        }
        {expand &&
                    <div className="service-container-expanded">
                        <img src={image} alt={title}></img>
                        <div className='text'>
                            <div className="heading">
                                    <div className="title">
                                        {title.toUpperCase()}
                                    </div>
                                <IoIosArrowDropdownCircle
                                    className="dropdown-btn"
                                    size="40px"
                                    onClick={()=>setExpand(!expand)}
                                />
                            </div>
                            <div className='body'>{description}</div>
                        </div>
                    </div>
                }
        </>
    )
}


function Services() {
    console.log("services:", services)
    return (
        <>
        <div id="Services">
            <h3>OUR SERVICES</h3>
            {services.map((service)=> {
                return(
                    <ScrollAnimation animateIn="fadeIn">
                        <ServiceContainer
                            image= {service.img}
                            title = {service.title}
                            description = {service.description}
                            key = {services.indexOf(service)}
                        />
                    </ScrollAnimation>
                )
            })}

            {/* <div className="services">
                    <div className="container">
                        <img src={service1} alt="Garage door opener"/>
                        <h4 className='text'>Garage Door Opener</h4>
                        <div className="description">A garage door opener is a device that automatically opens and closes the garage door at the touch of a button, mounted on the ceiling and operated by a motor driving a trolley along a rail. It offers convenience and security for homeowners, allowing them to access the garage easily without having to manually lift and lower the door.</div>


                    </div>
                    <div className="container">
                        <img src={service2} alt="Garage door opening" />
                        <h4 className='text'>Springs And Off-Track
                        </h4>
                        <div className="description">
                            A garage door opener is a device that automatically opens and closes the garage door at the touch of a button, mounted on the ceiling and operated by a motor driving a trolley along a rail. It offers convenience and security for homeowners, allowing them to access the garage easily without having to manually lift and lower the door.Springs are an essential component of a garage door system that help to counterbalance the weight of the door, making it easier to open and close. There are two main types of garage door springs: torsion springs, which are mounted above the door and unwind to lift the door, and extension springs, which are mounted on the sides of the door and stretch to lift the door.
                            <br></br>
                            <br></br>
                            Off-track doors occur when the garage door rollers come out of the tracks, causing the door to become misaligned and unable to open or close properly. This can happen due to worn out rollers, loose tracks, or other issues with the door mechanism. Off-track doors can be a safety hazard and should be repaired by a professional technician to ensure the door operates safely and smoothly.

                        </div>

                    </div>
                    <div className="container">
                        <img src={service3} alt="Garage door" />
                        <h4 className='text'>Garage Door</h4>
                        <div className="description">A garage Door can be operated manually or with a garage door opener. Garage doors come in a variety of styles, sizes, and designs to suit different architectural styles and personal preferences. They play a key role in providing security and protection for the items stored in the garage. Regular maintenance and proper installation are important to ensure the garage door functions correctly and safely.
                        </div>

                    </div>
                    <div className="container">
                        <img src={service4} alt="repair tools" />
                        <h4 className='text'>Repairs</h4>
                        <div className="description">We fix any type of Garage Doors and Garage Door Openers. With quality parts and brand new parts.

                        </div>
                    </div>
            </div>
            <div className="right"></div> */}
        </div>
        </>
    )
}

export default Services;
