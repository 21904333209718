import "./Testimonials.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import { IoIosArrowRoundForward } from "react-icons/io";
import SimpleSlider from "./Carousel";


function Testimonials() {
    return (
        <ScrollAnimation animateIn="fadeIn">
        <div id="Testimonials">
            <div className="header">
                <h1 className="container">
                    Hear from our satisfied customers.
                </h1>
                <div className="links">
                    {/* <Link to="https://www.facebook.com/profile.php?id=61564215692458&sk=reviews">
                        <FaFacebook className="facebook" size="80px"/>
                    </Link>
                    <Link to="https://www.yelp.com/">
                        <FaYelp className="yelp" size="80px"/>
                    </Link> */}
                    <Link to="/reviews">
                        <IoIosArrowRoundForward size="120px" className="arrow"/>
                    </Link>
                    <div>View all</div>
                </div>
            </div>
            <SimpleSlider />
            <div className="header2">
                <h1 className="container">
                    Let us know what you thought of our service!
                </h1>
                <div className="links">
                    {/* <Link to="https://www.facebook.com/profile.php?id=61564215692458&sk=reviews">
                        <FaFacebook className="facebook" size="80px"/>
                    </Link>
                    <Link to="https://www.yelp.com/">
                        <FaYelp className="yelp" size="80px"/>
                    </Link> */}
                    <Link to="/reviews/new">
                        <IoIosArrowRoundForward size="120px" className="arrow"/>
                    </Link>
                    <div>Leave a review</div>
                </div>
            </div>

        </div>
        </ScrollAnimation>
    )
}

export default Testimonials;
